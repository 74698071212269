// react
import React from 'react';

// third-party
import { Helmet } from 'react-helmet-async';
import moment from 'moment';
import 'moment/locale/es';
import PropTypes from 'prop-types';

// application
import PageHeader from '../shared/PageHeader';


function SitePagePrivacyPolicies(props) {
    const { company, privacyPolicies } = props;
    const modified = moment(privacyPolicies.modified_on, 'YYYY/MM/DD HH:mm:ss').locale('es').format('LL');

    const breadcrumb = [
        { title: 'Home', url: '/' },
        { title: 'Términos y Condiciones', url: '' },
    ];

    return (
        <React.Fragment>
            <Helmet>
                <title>{`Políticas de Privacidad — ${company.name}`}</title>
            </Helmet>

            <PageHeader breadcrumb={breadcrumb} />

            <div className="block">
                <div className="container">
                    <div className="document">
                        <div className="document__header">
                            <h1 className="document__title">Políticas de Privacidad</h1>
                            <div className="document__subtitle">{`Este Acuerdo fue modificado por última vez el ${modified}`}</div>
                        </div>
                        <div
                            className="document__content typography"
                            dangerouslySetInnerHTML={{ __html: privacyPolicies.body }}
                        />
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
SitePagePrivacyPolicies.propTypes = {
    privacyPolicies: PropTypes.object,
};

SitePagePrivacyPolicies.defaultProps = {
    privacyPolicies: {},
};
export default SitePagePrivacyPolicies;
