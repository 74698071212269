// react
import React from 'react';
// third-party
import { Helmet } from 'react-helmet-async';

// blocks
import BlockBrands from '../blocks/BlockBrands';
import BlockCategories from '../blocks/BlockCategories';
import BlockFeatures from '../blocks/BlockFeatures';
import BlockSlideShow from '../blocks/BlockSlideShow';


export default function HomePageOne(props) {
    const { categories, brands } = props;

    return (
        <React.Fragment>
            <Helmet>
                <title>Ingeniería y Controles Automatizados</title>
            </Helmet>

            <BlockSlideShow />

            <BlockFeatures />

            <BlockCategories title="Categorias" layout="classic" categories={categories} />

            <BlockBrands brands={brands} />

        </React.Fragment>
    );
}