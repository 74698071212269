// react
import React from 'react';

// third-party
import { connect } from 'react-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';

// application
import FilterSelect from '../filters/FilterSelect';
import { resetFilters } from '../../store/pagesContent';


function WidgetFilters(props) {
    const { title, filters, offcanvas, resetFilters, numFilterOn } = props;

    const filtersList = filters.map((filter) => {
        let filterView;

        if (filter.type === 'select') {
            filterView = <FilterSelect filter={filter} />;
        }

        return (
            <div key={filter.id} className="">
                {filterView}
            </div>
        );
    });

    const classes = classNames('widget-filters widget', {
        'widget-filters--offcanvas--always': offcanvas === 'always',
        'widget-filters--offcanvas--mobile': offcanvas === 'mobile',
    });

    return (
        <div className={classes}>
            <h4 className="widget-filters__title widget__title">{title}</h4>
            <div className="widget-filters__actions d-flex" align="center">
                <button type="button" className="btn btn-secondary btn-sm ml-2" onClick={() => resetFilters()} disabled={numFilterOn < 1}>Borrar Filtros</button>
            </div>
            <div className="widget-filters__list">
                {filtersList}
            </div>
        </div>
    );
}

WidgetFilters.propTypes = {
    /**
     * widget title
     */
    title: PropTypes.node,
    /**
     * array of filters
     */
    filters: PropTypes.array,
    /**
     * indicates when sidebar bar should be off canvas
     */
    offcanvas: PropTypes.oneOf(['always', 'mobile']),
};

const mapStateToProps = (state) => ({
    numFilterOn: state.pagesContent.filters.length,
});
const mapDispatchToProps = {
    resetFilters,
};
export default connect(mapStateToProps, mapDispatchToProps)(WidgetFilters);