// react
import React from 'react';

// third-party
import { Helmet } from 'react-helmet-async';

// application
import PageHeader from '../shared/PageHeader';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import publicIp from 'public-ip';
import { addMessage } from '../../store/directusSdk';


function SitePageContactUsAlt(props) {
    const { company } = props;
    const breadcrumb = [
        { title: 'Home', url: '' },
        { title: 'Contact Us', url: '' },
    ];
    const addresses = company.address.split('$');
    const emails = company.email.split('$');
    const phones = company.phone.split('$');

    return (
        <React.Fragment>
            <Helmet>
                <title>{`Contáctenos — ${company.name}`}</title>
            </Helmet>

            <PageHeader header="Contáctenos" breadcrumb={breadcrumb} />

            <div className="block">
                <div className="container">
                    <div className="card mb-0 contact-us">
                        <div className="contact-us__map">
                            <iframe
                                title="Google Map"
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3940.7358525732034!2d-79.53541288584614!3d8.996435993543486!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zOMKwNTknNDcuMiJOIDc5wrAzMSc1OS42Ilc!5e0!3m2!1ses-419!2spa!4v1590878038430!5m2!1ses-419!2spa"
                                frameBorder="0"
                                scrolling="no"
                                marginHeight="0"
                                marginWidth="0"
                            />
                        </div>
                        <div className="card-body">
                            <div className="contact-us__container">
                                <div className="row">
                                    <div className="col-12 col-lg-6 pb-4 pb-lg-0">
                                        <h4 className="contact-us__header card-title">Contáctenos</h4>

                                        <div className="contact-us__address" align="justify">
                                            {company.contactus_comment}

                                            <ul className="footer-contacts__contacts">
                                                <strong>Panamá</strong>
                                                <li>
                                                    <i className="footer-contacts__icon fas fa-home" />
                                                    {addresses[0]}
                                                </li>
                                                <li>
                                                    <i className="footer-contacts__icon far fa-envelope" />
                                                    {emails[0]}
                                                </li>
                                                <li>
                                                    <i className="footer-contacts__icon fas fa-phone-alt" />
                                                    {phones[0]}
                                                </li>
                                                <li>
                                                    <i className="footer-contacts__icon far fa-clock" />
                                                    {company && company.attention_hours}
                                                </li>
                                            </ul>
                                            <ul className="footer-contacts__contacts">
                                                <strong>Nicaragua</strong>
                                                <li>
                                                    <i className="footer-contacts__icon fas fa-home" />
                                                    {addresses[1]}
                                                </li>
                                                <li>
                                                    <i className="footer-contacts__icon far fa-envelope" />
                                                    {emails[1]}
                                                </li>
                                                <li>
                                                    <i className="footer-contacts__icon fas fa-phone-alt" />
                                                    {phones[1]}
                                                </li>
                                                <li>
                                                    <i className="footer-contacts__icon far fa-clock" />
                                                    {company && company.attention_hours}
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div className="col-12 col-lg-6">
                                        <h4 className="contact-us__header card-title">Déjanos un mensaje</h4>
                                        <Formik
                                            initialValues={{ name: '', email: '', subject: '', message: '' }}
                                            validationSchema={Yup.object({
                                                name: Yup.string()
                                                    .min(3, 'Minimo 3 caracteres.')
                                                    .required('Campo requerido.'),
                                                email: Yup.string()
                                                    .email('Correo electrónico invalido.')
                                                    .required('Campo requerido.'),
                                                subject: Yup.string()
                                                    .required('Campo requerido.'),
                                                message: Yup.string()
                                                    .required('Campo requerido.'),
                                            })}
                                            onSubmit={async (values, { setSubmitting, setErrors, setStatus, resetForm }) => {
                                                const message = Object.assign({}, values);
                                                try {
                                                    message.ip_client = await publicIp.v4();
                                                    await addMessage(message).then(() => {
                                                        toast.success('¡Mensaje enviado con exito!');
                                                    });
                                                    resetForm({});
                                                    setStatus({ success: true });
                                                } catch (error) {
                                                    setStatus({ success: false });
                                                    setSubmitting(false);
                                                    setErrors({ submit: error.message });
                                                }
                                            }}
                                        >
                                            {({ errors, touched, isSubmitting }) => (
                                                <Form autoComplete="off">
                                                    <div className="form-row">
                                                        <div className="form-group col-md-6">
                                                            <label htmlFor="name">Nombres</label>
                                                            <Field
                                                                name="name"
                                                                id="name"
                                                                className={`form-control ${errors.name && touched.name && 'is-invalid'}`}
                                                                placeholder="Nombres"
                                                            />
                                                            <ErrorMessage name="name" component="div" className="invalid-feedback" />
                                                        </div>
                                                        <div className="form-group col-md-6">
                                                            <label htmlFor="email">Correo Electrónico</label>
                                                            <Field
                                                                name="email"
                                                                id="email"
                                                                className={`form-control ${errors.email && touched.email && 'is-invalid'}`}
                                                                placeholder="alguien@dominio.com"
                                                            />
                                                            <ErrorMessage name="email" component="div" className="invalid-feedback" />
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="subject">Asunto</label>
                                                        <Field
                                                            name="subject"
                                                            id="subject"
                                                            className={`form-control ${errors.subject && touched.subject && 'is-invalid'}`}
                                                            placeholder="Asunto"
                                                        />
                                                        <ErrorMessage name="subject" component="div" className="invalid-feedback" />
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="message">Mensaje</label>
                                                        <Field
                                                            name="message"
                                                            id="message"
                                                            as="textarea"
                                                            className={`form-control ${errors.message && touched.message && 'is-invalid'}`}
                                                            placeholder="Mensaje..."
                                                            rows="4"
                                                        />
                                                        <ErrorMessage name="message" component="div" className="invalid-feedback" />
                                                    </div>
                                                    <button type="submit" className="btn btn-primary" disabled={isSubmitting}>{isSubmitting ? 'Enviando...' : 'Enviar'}</button>

                                                </Form>
                                            )}
                                        </Formik>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default SitePageContactUsAlt;
