import {
    PAGESCONTENT_CHANGE,
    FILTERS_CHANGE,
    RESET_FILTERS,
    DROP_FILTER,
} from './pagesContentActionTypes';


// eslint-disable-next-line import/prefer-default-export
export const pagesContentChange = (pagesContent) => ({
    type: PAGESCONTENT_CHANGE,
    pagesContent,
});
export const filtersChange = (filter) => ({
    type: FILTERS_CHANGE,
    filter,
});
export const dropFilters = (filter) => ({
    type: DROP_FILTER,
    filter,
});
export const resetFilters = () => ({
    type: RESET_FILTERS,
});
