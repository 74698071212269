// react
import React from 'react';

// third-party
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import Dropdown from './Dropdown';



function Topbar(props) {

    const {currentUser, history} = props;

    const links = [
        { title: <FormattedMessage id="topbar.aboutUs" defaultMessage="About Us" />, url: '/site/about-us' },
        { title: <FormattedMessage id="topbar.contacts" defaultMessage="Contacts" />, url: '/site/contact-us' },
        { title: <FormattedMessage id="topbar.projects" defaultMessage="Proyectos" />, url: '/proyectos' },
        { title: <FormattedMessage id="topbar.storeLocation" defaultMessage="Store Location" />, url: '/site/contact-us' },
    ];
    const accountLinks = [
        { title: 'Mi Perfil', url: '/account/dashboard' },
        { title: 'Mis Cotizaciones', url: '/account/cotizaciones' },
        { title: 'Mis Órdenes', url: '/account/orders' },
        { title: 'Mis Direcciones', url: '/account/addresses' },
        { title: 'Contraseña', url: '/account/password' },
        { title: 'Salir', url: '#' },
    ];
    const linksList = links.map((item, index) => (
        <div key={index} className="topbar__item topbar__item--link">
            {item.title !== 'Salir' ? <Link className="topbar-link" to={item.url}>{item.title}</Link> : <Link className="topbar-link" to={item.url} onClick={(e) => e.preventDefault()}>{item.title}</Link>}
        </div>
    ));

    return (
        <div className="site-header__topbar topbar">
            <div className="topbar__container container">
                <div className="topbar__row">
                    {linksList}
                    <div className="topbar__spring" />
                    <div className="topbar__item">
                        {!currentUser ? (
                            <div className="topbar__item topbar__item--link">
                                <Link className="topbar-link" to="/account/login"><b>Ingresar ó Registrarse</b></Link>
                            </div>
                        ) : (
                            <Dropdown
                                title={`${currentUser.first_name} ${currentUser.last_name}`}
                                items={accountLinks}
                                history={history}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Topbar;
