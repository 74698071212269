// react
import React, { Component } from 'react';

// third-party
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { Link, Redirect } from 'react-router-dom';

// application
import Collapse from '../shared/Collapse';
import Currency from '../shared/Currency';
import PageHeader from '../shared/PageHeader';
import { Check9x7Svg } from '../../svg';
import SelectAddresses from './SelectAddresses';
import LoginRegister from './LoginRegister';
import { getCustomerAddress, AddOrder } from '../../store/directusSdk';
import { cartRemoveAllItems } from '../../store/cart';


class ShopPageCheckout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            paymenttypeid: null,
            itbms: 0,
            quantity: 0,
            subtotal: 0,
            total: 0,
            lines: [],
            file: null,
            ivalidFormat: null,
            billingaddress: null,
            shippingaddress: null,
            terms: false,
            sending: false,
            envio: 0,
            msg_envi: '',
            delivery: false,
        };
        this.reader = new FileReader();
    }

    static getDerivedStateFromProps(props, state) {
        if (props.cart.total !== state.total) {
            return {
                paymenttypeid: props.paymentss[0],
                itbms: props.cart.itbms,
                quantity: props.cart.quantity,
                subtotal: props.cart.subtotal,
                envio: props.cart.shipping,
                total: props.cart.total,
                lines: props.cart.items,
            };
        }
        return null;
    }

    validate = () => {
        const { paymenttypeid, shippingaddress, billingaddress, file, terms } = this.state;
        const errors = {};
        if ((paymenttypeid.key === 'bank' || paymenttypeid.key === 'purchase_order') && file === null) {
            errors.payment = 'Ingrese comprobante.';
        }
        if (paymenttypeid.key === 'cash' && shippingaddress !== 'Rerirar en Sitio') {
            errors.payment = 'Solo para retirar en sitio.';
        }
        if (shippingaddress === null || shippingaddress === '-1') {
            errors.shippingaddress = 'Dirección de envio invalida.';
        }
        if (billingaddress === null) {
            errors.billingaddress = 'Dirección de facturación invalida.';
        }
        if (!terms) {
            errors.terms = 'Debe aceptar los términos y condiciones.';
        }
        if (Object.keys(errors).length > 0) {
            return errors;
        }
        return errors;
    }

    onSubmit = () => {
        const { paymenttypeid, shippingaddress, billingaddress, itbms, quantity, file, subtotal, total, terms, envio, msg_envi } = this.state;
        const { history, cart, cartRemoveAllItems } = this.props;
        const shipping = shippingaddress === 'Rerirar en Sitio' ? 'Rerirar en Sitio' : `${shippingaddress.responsable} - ${shippingaddress.phone1} - ${shippingaddress.address}`;
        const orderlines = cart.items.map((item) => ({
            productcode: item.product.code,
            productname: item.product.name,
            quantity: item.quantity,
            price: item.price,
            subtotal: item.total,
        }));
        let lines = '';
        orderlines.forEach((e, i) => {
            lines += `<tr>
                <td style="padding:5px 5px; text-align: center" >${i + 1}</td>
                <td style="padding:5px 5px; text-align: center">${e.productcode}</td>
                <td style="padding:5px 5px; text-align: center" >${e.productname}</td>
                <td style="padding:5px 5px; text-align: center" >${e.quantity}</td>
                <td style="padding:5px 5px; text-align: right" >$ ${e.price}</td>
                <td style="padding:5px 5px; text-align: right" >$ ${e.subtotal}</td>
            </tr>`;
        });
        let attachment;
        if (file) {
            const form = document.querySelector(`#fileForm_${paymenttypeid.key}`);
            attachment = new FormData(form);
        } else {
            attachment = null;
        }
        const htmlmsg = `<h1 align="center"><strong>Nueva Orden</strong></h1>
        <p>Se ha solicitado una orden con numero # {{orderid}} <a href="${process.env.REACT_APP_HOST}/account/order/{{orderid}}">&nbsp;&nbsp;&nbsp;Ver&nbsp;en&nbsp;ICAutomatizados.</a></p>
        <table style="width: 45%;" cellspacing="1" cellpadding="1" align="left">
        <tbody>
        <tr>
        <td><strong>Tipo de Pago:</strong></td>
        <td>${paymenttypeid.title}</td>
        </tr>
        </tbody>
        </table>
        <p>&nbsp;</p>
        <hr style="background: #004FBD; margin-top: 30px;" size="4" width="95%" />
        <h2 align="center"><strong>Lista de Productos</strong></h2>
        <table id="tabla" style="width: 90%; margin-bottom: 20px; margin-left: auto; margin-right: auto;" border="1" cellspacing="1" cellpadding="1" align="center">
        <thead>
        <tr style="background: #eee;">
        <th style="padding: 5px 10px;" width="6%">#</th>
        <th style="padding: 5px 10px;" width="18%">C&oacute;digo</th>
        <th style="padding: 5px 10px;" width="42%">Producto</th>
        <th style="padding: 5px 10px;" width="10%">Cant</th>
        <th style="padding: 5px 10px;" width="12%">Precio</th>
        <th style="padding: 5px 10px;" width="12%">Total</th>
        </tr>
        </thead>
        <tbody>
        ${lines}
        </tbody>
        </table>
        <p>&nbsp;</p>
        <hr style="background: #004FBD;" size="4" width="95%" />
        <p>&nbsp;</p>
        <table style="width: 45%; float: right;" border="1" cellspacing="1" cellpadding="1" align="left">
        <tbody>
        <tr>
        <th style="text-align: left; padding: 5px 10px; background: #eee;" scope="row">SUBTOTAL</th>
        <td style="text-align: right; padding: 5px 10px;">$ ${subtotal.toFixed(2)}</td>
        </tr>
        <tr>
        <th style="text-align: left; padding: 5px 10px; background: #eee;" scope="row">ENVIO</th>
        <td style="text-align: right; padding: 5px 10px;">$ ${envio.toFixed(2)}</td>
        </tr>
        <tr>
        <th style="text-align: left; padding: 5px 10px; background: #eee;" scope="row">ITBMS</th>
        <td style="text-align: right; padding: 5px 10px;">$ ${itbms.toFixed(2)}</td>
        </tr>
        <tr>
        <th style="text-align: left; padding: 5px 10px; background: #eee;" scope="row">TOTAL</th>
        <td style="text-align: right; padding: 5px 10px;">$ ${total.toFixed(2)}</td>
        </tr>
        </tbody>
        </table>
        <p>&nbsp;</p>`;
        const order = {
            paymenttypeid: paymenttypeid.id,
            shippingaddress: shipping,
            billingaddress: `${billingaddress.responsable} - ${billingaddress.phone1} - ${billingaddress.address}`,
            itbms,
            quantity,
            subtotal,
            envio,
            msg_envi: cart.msgEnvio,
            total,
            attachment,
            terms,
            orderlines,
            htmlmsg,
        };
        this.setState({ sending: true, msg_envi: cart.msgEnvio, delivery: cart.delivery });
        AddOrder(order)
            .then((res) => {
                const checked = this.state;
                delete checked.attachment;
                checked.numberOrder = res.id;
                checked.pending = true;
                checked.createdDate = res.created_on;
                cartRemoveAllItems();
                if (paymenttypeid.key === 'credit_card') {
                    localStorage.setItem('ord', JSON.stringify(checked));
                    history.replace('/shop/checkout/payment');
                } else {
                    history.replace('/shop/checkout/success', checked);
                }
            })
            .catch(() => this.setState({ sending: false }));
    }

    onChangeFile = (event) => {
        if (event.target.files[0]) {
            const file = event.target.files[0];
            if (!file) {
                this.setState(() => ({ file: null, ivalidFormat: 'Seleccione un archivo!' }));
                return false;
            }
            if (!file.name.match(/\.(jpg|jpeg|png|gif|pdf)$/)) {
                this.setState(() => ({ file: null, ivalidFormat: 'Formato invalido!' }));
                return false;
            }
            if (file.size > 2000000) {
                this.setState(() => ({ file: null, ivalidFormat: 'Archivo demaciado grande!' }));
                return false;
            }
            return this.setState(() => ({ file, ivalidFormat: null }));
        }
        return this.setState(() => ({ file: null, ivalidFormat: null }));
    }

    handlePaymentChange = (event) => {
        if (event.target.checked) {
            const paymenttypeid = JSON.parse(event.target.value);
            if (paymenttypeid.key !== 'bank') {
                const inputsFile = document.querySelector('#filebank');
                inputsFile.value = null;
            }
            if (paymenttypeid.key !== 'purchase_order') {
                const inputsFile = document.querySelector('#filepurchase_order');
                inputsFile.value = null;
            }
            return this.setState(() => ({ paymenttypeid, ivalidFormat: null, file: null }));
        }
    }

    handleTermsChange = (event) => {
        if (event.target.checked) {
            return this.setState({ terms: true });
        }
        return this.setState(() => ({ terms: false }));
    }

    handleAddressChange = (type, address) => {
        if (type === 'billing') {
            if (!address) {
                const { currentUser } = this.props;
                return this.setState(() => ({ billingaddress: (currentUser && currentUser.addresses.length > 0 ? currentUser.addresses[0] : null) }));
            }
            return this.setState(() => ({ billingaddress: address }));
        }
        if (type === 'shipping') {
            if (!address || address.id === '0') {
                return this.setState(() => ({ shippingaddress: 'Rerirar en Sitio' }));
            }
            if (address.id === '-1') {
                return this.setState(() => ({ shippingaddress: null }));
            }
            return this.setState(() => ({ shippingaddress: address }));
        }
        if (type === 'default') {
            return this.setState(() => ({ shippingaddress: address, billingaddress: address }));
        }
    }

    renderTotals() {
        const { cart } = this.props;

        return (
            <React.Fragment>
                <tbody className="checkout__totals-subtotals">
                    <tr>
                        <th>Subtotal</th>
                        <td><Currency value={cart.subtotal} /></td>
                    </tr>
                    <tr>
                        <th>Envio</th>
                        <td><Currency value={cart.shipping} /></td>
                    </tr>
                    <tr>
                        <th>ITBMS</th>
                        <td><Currency value={cart.itbms} /></td>
                    </tr>
                </tbody>
            </React.Fragment>
        );
    }

    renderCart() {
        const { cart } = this.props;
        const items = cart.items.map((item) => (
            <tr key={item.id}>
                <td>{`${item.product.name} × ${item.quantity}`}</td>
                <td><Currency value={item.total} /></td>
            </tr>
        ));

        return (
            <table className="checkout__totals">
                <thead className="checkout__totals-header">
                    <tr>
                        <th>Producto</th>
                        <th>Total</th>
                    </tr>
                </thead>
                <tbody className="checkout__totals-products">
                    {items}
                </tbody>
                {this.renderTotals()}
                <tfoot className="checkout__totals-footer">
                    <tr>
                        <th>Total</th>
                        <td><Currency value={cart.total} /></td>
                    </tr>
                </tfoot>
            </table>
        );
    }

    renderPaymentsList(errors) {
        const { paymenttypeid: currentPayment, ivalidFormat, sending } = this.state;
        const { paymentss, cart } = this.props;

        const payments = paymentss.map((payment) => {
            const renderPayment = ({ setItemRef, setContentRef }) => (
                <li className="payment-methods__item" ref={setItemRef}>
                    <label className="payment-methods__item-header">
                        <span className="payment-methods__item-radio input-radio">
                            <span className="input-radio__body">
                                <input
                                    type="radio"
                                    className="input-radio__input"
                                    name="checkout_payment_method"
                                    value={JSON.stringify(payment)}
                                    checked={currentPayment.id == payment.id}
                                    onChange={this.handlePaymentChange}
                                    disabled={sending || (payment.key === 'cash' && cart.incEnvio)}
                                />
                                <span className="input-radio__circle" />
                            </span>
                        </span>
                        <span className="payment-methods__item-title">{payment.title}</span>
                    </label>
                    <div className="payment-methods__item-container" ref={setContentRef}>
                        <div className="payment-methods__item-description text-muted">
                            {payment.description}
                            <br />
                            {(payment.key === 'bank' || payment.key === 'purchase_order') && (
                                <form id={`fileForm_${payment.key}`} encType="multipart/form-data">
                                    <br />
                                    <input
                                        type="file"
                                        id={`file${payment.key}`}
                                        name="attachment"
                                        accept="image/*, .pdf"
                                        onChange={this.onChangeFile}

                                    />
                                    <div className="">
                                        {ivalidFormat && (
                                            <span style={{ fontSize: '90%', color: 'red' }}>
                                                {ivalidFormat}
                                            </span>
                                        )}
                                        {errors.payment && !ivalidFormat && (
                                            <span style={{ fontSize: '90%', color: 'red' }}>
                                                {errors.payment}
                                            </span>
                                        )}
                                        <br />
                                    </div>
                                </form>
                            )}
                            {payment.key === 'cash' && errors.payment && (
                                <span style={{ fontSize: '90%', color: 'red' }}>
                                    {errors.payment}
                                </span>
                            )}
                            <br />
                        </div>
                    </div>
                </li>
            );
            return (
                <Collapse
                    key={payment.id}
                    open={currentPayment.id == payment.id}
                    toggleClass="payment-methods__item--active"
                    render={renderPayment}
                />
            );
        });

        return (
            <div className="payment-methods">
                <ul className="payment-methods__list">
                    {payments}
                </ul>
            </div>
        );
    }

    render() {
        const { cart, currentUser } = this.props;
        const { sending } = this.state;

        if (cart.items.length < 1 || !currentUser) {
            return <Redirect to="cart" />;
        }
        const errors = this.validate();

        const breadcrumb = [
            { title: 'Home', url: '/' },
            { title: 'Carrito de Compras', url: '/shop/cart' },
            { title: 'Pedido', url: '' },
        ];

        return (
            <React.Fragment>
                <Helmet>
                    <title>Realizar Pedido - ICAutomatizados S.A</title>
                </Helmet>

                <PageHeader header="Pedido" breadcrumb={breadcrumb} />

                <div className="checkout block">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-lg-6 col-xl-7">
                                {currentUser && (
                                    <SelectAddresses
                                        cart={cart}
                                        currentUser={currentUser}
                                        handleAddressChange={this.handleAddressChange}
                                        errors={errors}
                                        sending={sending}
                                    />
                                )}
                            </div>

                            <div className="col-12 col-lg-6 col-xl-5 mt-4 mt-lg-0">
                                <div className="card mb-0">
                                    <div className="card-body">
                                        <h3 className="card-title">Su Pedido</h3>

                                        {this.renderCart()}

                                        {this.renderPaymentsList(errors)}

                                        <div className="checkout__agree form-group">
                                            <div className="form-check">
                                                <span className="form-check-input input-check">
                                                    <span className="input-check__body">
                                                        <input className="input-check__input" type="checkbox" id="checkout-terms" onChange={this.handleTermsChange} value="true" disabled={sending} />
                                                        <span className="input-check__box" />
                                                        <Check9x7Svg className="input-check__icon" />
                                                    </span>
                                                </span>
                                                <label className="form-check-label" htmlFor="checkout-terms">
                                                    He leído y acepto los &nbsp;
                                                    <Link to="/site/terms" target="__blank">términos y condiciones</Link>
                                                    *
                                                </label>
                                                {errors.terms && (
                                                    <span style={{ fontSize: '90%', color: 'red' }}>
                                                        {errors.terms}
                                                    </span>
                                                )}
                                            </div>
                                        </div>

                                        <button type="submit" className={`btn btn-primary btn-xl btn-block ${sending && 'btn-loading'}`} onClick={this.onSubmit} disabled={Object.keys(errors).length > 0}>Comprar</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}


const mapStateToProps = (state) => ({
    cart: state.cart,
    paymentss: state.pagesContent.payments,
});

const mapDispatchToProps = {
    cartRemoveAllItems,
};

export default connect(mapStateToProps, mapDispatchToProps)(ShopPageCheckout);
