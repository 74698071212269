// react
import React, { Component } from 'react';

// third-party
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';

// application
import PageHeader from '../shared/PageHeader';
import Pagination from '../shared/Pagination';
import ProjectCard from '../shared/ProjectCard';


export default class PageProjectsList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            page: 1,
            records: 6,
            pages: 1,
            start: null,
            end: null,
            projectsList: [],
        };
    }

    componentDidMount() {
        const { projects, layout } = this.props;
        const { records, page } = this.state;
        const projectsList = projects.map((project) => {
            const postLayout = {
                classic: 'grid-lg',
                grid: 'grid-nl',
                list: 'list-nl',
            }[layout];

            return (
                <div key={project.id} className="posts-list__item">
                    <ProjectCard project={project} layout={postLayout} />
                </div>
            );
        });
        const pages = Math.ceil(projects.length / records);
        const end = page * records;
        const start = end - records;

        this.setState(() => ({ pages, end, start, projectsList }));
    }

    handlePageChange = (page) => {
        const { records } = this.state;
        const end = page * records;
        const start = end - records;

        this.setState(() => ({ end, start, page }));
    };

    render() {
        const { layout, company } = this.props;
        const { page, projectsList, start, end, pages } = this.state;

        const breadcrumb = [
            { title: 'Home', url: '/' },
            { title: 'Proyectos', url: '' },
        ];

        return (
            <React.Fragment>
                <Helmet>
                    <title>{`Proyectos — ${company && company.name}`}</title>
                </Helmet>

                <PageHeader header="Proyectos" breadcrumb={breadcrumb} />

                <div className="container">
                    <div className="row">
                        <div className="col-12 col-lg-12">
                            <div className="block">
                                <div className="posts-view">
                                    <div className={`posts-view__list posts-list posts-list--layout--${layout}`}>
                                        <div className="posts-list__body">
                                            {projectsList.slice(start, end)}
                                        </div>
                                    </div>
                                    <div className="posts-view__pagination">
                                        {pages > 1 && (
                                            <Pagination
                                                current={page}
                                                siblings={2}
                                                total={pages}
                                                onPageChange={this.handlePageChange}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

PageProjectsList.propTypes = {
    /**
     * blog layout
     * one of ['classic', 'grid', 'list'] (default: 'classic')
     */
    layout: PropTypes.oneOf(['classic', 'grid', 'list']),
    /**
     * sidebar position (default: 'start')
     * one of ['start', 'end']
     * for LTR scripts "start" is "left" and "end" is "right"
     */
};

PageProjectsList.defaultProps = {
    layout: 'grid',
};
