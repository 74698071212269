import DirectusSDK from "@directus/sdk-js";
import { calcSubtotal, calcITBMS, calcTotal } from "./cart/cartReducer";

const client = new DirectusSDK({
    url: process.env.REACT_APP_API_URL,
    project: "icaweb",
    storage: window.localStorage,
    mode: "cookie",
});

export async function recalculatePrice(products) {
    const data = await client
        .getItems("products", {
            fields: "id, price",
            filter: {
                id: { in: products },
            },
            status: "published",
        })
        .then((res) => res.data);
    return data;
}

export async function getCurrentUser(id) {
    const data = await client
        .getUser(id, {
            fields: "id, first_name, last_name, email, phone, countryid.id, countryid.countrycode, countryid.countryname, addresses.address, addresses.customer, addresses.id, addresses.responsable, addresses.phone1, addresses.phone2, , addresses.email, addresses.defaultaddress, addresses.countryid.countrycode, addresses.countryid.countryname, addresses.countryid.id, addresses.deliverycityid.id, addresses.deliverycityid.cityname, addresses.deliverycityid.deliveryprices.delivery, addresses.deliverycityid.deliveryprices.freedelivery, addresses.deliverycityid.deliveryprices.price, addresses.deliverycityid.deliveryprices.topprice",
        })
        .then((user) => user.data);
    return data;
}

export async function login(email, password, cartProducts) {
    const data = await client.login({ email, password }).then(async (res) => {
        const user = await getCurrentUser(res.data.user.id);
        if (cartProducts.length > 0) {
            const productos = await recalculatePrice(cartProducts);
            return { user, productos };
        }
        return { user };
    });
    return data;
}

export async function logout(cartProducts) {
    const data = await client.logout().then(async (res) => {
        if (cartProducts.length > 0) {
            const productos = await recalculatePrice(cartProducts);
            return { res, productos };
        }
        return { res };
    });
    return data;
}

export async function ChangedPassword(id, newPass) {
    const data = await client.updateUser(id, {
        password: newPass,
    });
    return data;
}

export async function registerUser(newUser) {
    const data = await client.createItem("directus_users", newUser);
    return data;
}

export async function getProductsBySubCategory(id) {
    const datas = await client.getItems("products", {
        fields: "id,code,code_manufacture,name,brand,model,description,price,amp,hp,kw,voltaje,rpm,frame,polos,fp,efficiency,fs,type,connection,phases,temperature,output,input,pressure,flow,color,seal,diameter,thread,application,protection,mounting,communication,pulses,curve,capacity,unit,rank,long,size,caliber,pines,hours,minutes,seconds,function,memory,contacts,coil,material,available,OnOrder,extras,pdfs,video,especificaciones,accesories,images,days,alternatives,OnHandByWhs,fprice,width,height,volumen,weight," +
            "external_inventories.id," +
            "external_inventories.qty,",
        filter: {
            subcategoryid: { eq: id },
        },
        status: "published",
        sort: "-available,-OnOrder",
        limit: -1,
    });
    const data = datas.data.map((pr) => {
        let tqty = 0;
        pr.external_inventories && pr.external_inventories.map((ele) => {
            tqty += parseInt(ele.qty)
        })
        pr.externalQty = tqty
        return ({ ...pr })
    })
    return ({ data: data });
}

export async function getProduct(id) {
    const data = await client.getItem("products", id, {
        fields: "id,code,code_manufacture,name,brand,model,description,price,amp,hp,kw,voltaje,rpm,frame,polos,fp,efficiency,fs,type,connection,phases,temperature,output,input,pressure,flow,color,seal,diameter,thread,application,protection,mounting,communication,pulses,curve,capacity,unit,rank,long,size,caliber,pines,hours,minutes,seconds,function,memory,contacts,coil,material,available,OnOrder,extras,pdfs,video,especificaciones,accesories,images,days,alternatives,OnHandByWhs,fprice,width,height,volumen,weight,comments," +
            "subcategoryid.id," +
            "subcategoryid.descripcion," +
            "subcategoryid.categoriaid.id," +
            "subcategoryid.categoriaid.descripcion," +
            "external_inventories.id," +
            "external_inventories.qty," +
            "external_inventories.inventory_id.id," +
            "external_inventories.inventory_id.pseudonym," +
            "external_inventories.inventory_id.time_delivery"

    });
    const existence = []
    let externalQty = 0
    data &&
        data.data &&
        data.data.external_inventories &&
        data.data.external_inventories.forEach((element) => {
            externalQty += parseInt(element.qty);
            existence.push({
                qty: element.qty,
                inv: element.inventory_id.pseudonym,
                time: element.inventory_id.time_delivery,
            });
        });
    data.data.existence = existence;
    data.data.externalQty = externalQty;
    return data;
}

export async function getSuggestedProducts(string) {
    const data = await client.getItems("products", {
        fields: "*,OnOrder,subcategoryid.id,subcategoryid.descripcion,subcategoryid.categoriaid.id,subcategoryid.categoriaid.descripcion",
        filter: {
            name: {
                like: string,
            },
            code: {
                logical: "or",
                like: string,
            },
            code_manufacture: {
                logical: "or",
                like: string,
            },
            description: {
                logical: "or",
                like: string,
            },
        },
        status: "published",
        sort: "-available,-OnOrder",
    });
    return data;
}

export async function getSearchProducts(string) {
    const data = await client.getItems("products", {
        fields: "*,OnOrder,subcategoryid.id,subcategoryid.descripcion,subcategoryid.categoriaid.id,subcategoryid.categoriaid.descripcion",
        filter: {
            name: {
                like: string,
            },
            code: {
                logical: "or",
                like: string,
            },
            code_manufacture: {
                logical: "or",
                like: string,
            },
            description: {
                logical: "or",
                like: string,
            },
        },
        status: "published",
        sort: "-available,-OnOrder",
    });
    return data;
}

export async function getAccessories(codes) {
    const datas = await client.getItems("products", {
        fields: "*,OnOrder,subcategoryid.id,subcategoryid.descripcion,subcategoryid.categoriaid.id,subcategoryid.categoriaid.descripcion," +
            "external_inventories.id," +
            "external_inventories.qty,",
        filter: { code: { in: codes } },
        status: "published",
        sort: "-available,-OnOrder",
    });
    const data = datas.data.map((pr) => {
        let tqty = 0;
        pr.external_inventories && pr.external_inventories.map((ele) => {
            tqty += parseInt(ele.qty)
        })
        pr.externalQty = tqty
        return ({ ...pr })
    })
    return ({ data: data });
    return data;

}

export async function getAlternatives(codes) {
    const datas = await client.getItems("products", {
        fields: "*,OnOrder,subcategoryid.id,subcategoryid.descripcion,subcategoryid.categoriaid.id,subcategoryid.categoriaid.descripcion," +
            "external_inventories.id," +
            "external_inventories.qty,",
        filter: { code: { in: codes } },
        status: "published",
        sort: "-available,-OnOrder",
    });
    const data = datas.data.map((pr) => {
        let tqty = 0;
        pr.external_inventories && pr.external_inventories.map((ele) => {
            tqty += parseInt(ele.qty)
        })
        pr.externalQty = tqty
        return ({ ...pr })
    })
    return ({ data: data });

}

export async function getCustomerAddress(userId) {
    const data = await client.getItems("customeraddress", {
        fields: "id,address,customer,defaultaddress,responsable,email,phone1,phone2,owner,countryid.countryname,countryid.id,countryid.countrycode,deliverycityid.id,deliverycityid.cityname,deliverycityid.deliveryprices.delivery,deliverycityid.deliveryprices.freedelivery,deliverycityid.deliveryprices.topprice,deliverycityid.deliveryprices.price",
        filter: { owner: { eq: userId } },
        status: "published",
    });
    return data;
}

export async function getDefaultAddress(userId) {
    const data = await client.getItems("customeraddress", {
        fields: "id,responsable,address,phone1,phone2,email,defaultaddress,countryid.countrycode,countryid.countryname,countryid.id,deliverycityid.cityname,deliverycityid.id",
        status: "published",
        filter: {
            defaultaddress: { eq: 1 },
            owner: { eq: userId },
        },
    });
    return data;
}

export async function searchCustomerAddress(id) {
    const data = await client.getItem("customeraddress", id);
    return data;
}

export async function addCustomerAddress(address, userId) {
    if (address.defaultaddress) {
        let data;
        await client
            .getItems("customeraddress", {
                status: "published",
                filter: {
                    defaultaddress: { eq: 1 },
                    owner: { eq: userId },
                },
            })
            .then((res) => {
                if (res.data.length > 0) {
                    data = res.data.map((e) => {
                        e.defaultaddress = false;
                        return e;
                    });
                }
            });
        if (data) {
            await client.updateItems("customeraddress", data);
        }
    }
    const data = await client.createItem("customeraddress", address);
    return data;
}

export async function removeCustomerAddress(id) {
    const data = await client.deleteItem("customeraddress", id);
    return data;
}
export async function editCustomerAddress(body) {
    const data = await client.updateItems("customeraddress", body);
    return data;
}
export async function setDefaultAddress(data) {
    const dat = await client.updateItems("customeraddress", data);
    return dat;
}

export async function UploadEvidence(data, onUploadProgress) {
    const result = await client.uploadFiles(data, onUploadProgress);
    return result;
}

export async function AddOrder(order) {
    if (order.attachment) {
        await client.uploadFiles(order.attachment).then((res) => {
            order.attachment = res.data;
        });
    }
    const ord = await client.createItem("orders", order).then((res) => res.data);
    return ord;
}

export async function addTransactionLog(amount, orderid) {
    const data = await client.createItem("transactionlog", { amount, orderid });
    return data;
}

export async function addMessage(message) {
    const data = await client.createItem("contactform", message);
    return data;
}

export async function ResetPassword(email) {
    const data = await client.requestPasswordReset(email, `${process.env.REACT_APP_HOST}/cuenta/reestablecer-contrasena`);
    return data;
}

export async function resetedPassword(token, password) {
    const data = await client.api.post("/auth/password/reset", { token, password });
    return data;
}

export async function getOrders(cliente) {
    const data = await client.getItems("orders", {
        fields: "id, total, created_on, status",
        filter: { owner: { eq: cliente } },
        sort: "-id",
    });
    return data;
}

export async function getOrder(id) {
    const data = await client.getItem("orders", id, {
        fields: "id, billingaddress, shippingaddress, total, created_on, status, subtotal, itbms, orderlines.*",
    });
    return data;
}

export async function updateOrder(id, body) {
    const data = await client.updateItem("orders", id, body);
    return data;
}

export async function updateTransactionLog(id, formid) {
    const data = await client.updateItem("transactionlog", id, { formid });
    return data;
}

export async function CreateQuotation(quotation) {
    const data = await client.createItem("quotation", quotation).then((res) => res.data);
    return data;
}

export async function getQuotations(cliente) {
    const data = await client.getItems("quotation", {
        fields: "id, total, created_on, validity, items, cart",
        filter: { owner: { eq: cliente } },
        sort: "-id",
    });
    return data;
}

export async function getAvailablePrices(cart) {
    const ids = cart.items.map((e) => e.product.id);
    const data = await client
        .getItems("products", {
            fields: "id, price, OnHandByWhs",
            filter: { id: { in: ids } },
        })
        .then((res) => {
            res.data.map((e) => {
                const product = cart.items.findIndex((pro) => pro.product.id === e.id);
                if (product !== -1) {
                    if (e.OnHandByWhs) {
                        const bodegas = e.OnHandByWhs.split(",");
                        const bod01 = bodegas[0].split(":");
                        const bod05 = bodegas[0].split(":");
                        cart.items[product].product.bod01 = parseInt(bod01[1]);
                        cart.items[product].product.bod05 = parseInt(bod05[1]);
                        cart.items[product].price = e.price;
                    } else {
                        cart.items[product].product.bod01 = 0;
                        cart.items[product].product.bod05 = 0;
                    }
                } else {
                    console.log("no se encontro producto: " + e.id);
                }
            });
            return cart;
        });
    return data;
}

export async function getAvailable(cart) {
    const ids = cart.items.map((e) => e.product.id);
    const data = await client
        .getItems("products", {
            fields: "id,OnHandByWhs",
            filter: { id: { in: ids } },
        })
        .then((res) => {
            res.data.map((e) => {
                const product = cart.items.findIndex((pro) => pro.product.id === e.id);
                if (product !== -1) {
                    if (e.OnHandByWhs) {
                        const bodegas = e.OnHandByWhs.split(",");
                        const bod01 = bodegas[0].split(":");
                        const bod05 = bodegas[1].split(":");
                        cart.items[product].product.bod01 = parseInt(bod01[1]);
                        cart.items[product].product.bod05 = parseInt(bod05[1]);
                        cart.items[product].product.OnHandByWhs = e.OnHandByWhs;
                    } else {
                        console.log("no se encontro producto: " + e.id + " en cart -> items");
                    }
                } else {
                    console.log("no se encontro producto: " + e.id);
                }
            });
            cart.subtotal = calcSubtotal(cart.items);
            cart.itbms = calcITBMS(cart.subtotal, cart.shipping);
            cart.total = calcTotal(cart.subtotal, cart.shipping);
            return cart;
        });
    return data;
}

export async function getDeliveryCity(id) {
    const data = await client
        .getItem("deliverycities", id, {
            fields: "id, cityname, deliveryprices.id, deliveryprices.delivery, deliveryprices.freedelivery, deliveryprices.topprice, deliveryprices.price ",
        })
        .then((res) => res.data);
    return data;
}

export function getContent() {
    return client.api.post("/custom/get_content_pages");
}
