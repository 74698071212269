// react
import React from 'react';

export default function FooterNewsletter() {
    const socialLinks = [
        {
            key: 'facebook',
            url: 'https://www.facebook.com/icautomatizados',
            iconClass: 'fab fa-facebook-f',
        },
        {
            key: 'twitter',
            url: 'https://twitter.com/icautomatizados',
            iconClass: 'fab fa-twitter',
        },
        {
            key: 'youtube',
            url: 'https://www.youtube.com/user/icautomatizados',
            iconClass: 'fab fa-youtube',
        },
        {
            key: 'instagram',
            url: 'https://www.instagram.com/icautomatizados/',
            iconClass: 'fab fa-instagram',
        },
    ];

    const socialLinksList = socialLinks.map((item) => (
        <li key={item.key} className={`footer-newsletter__social-link footer-newsletter__social-link--${item.key}`}>
            <a href={item.url} target="_blank" rel="noopener noreferrer">
                <i className={item.iconClass} />
            </a>
        </li>
    ));

    return (
        <div className="site-footer__widget footer-newsletter">
            <h5 className="footer-newsletter__title">Noticias</h5>
            <div className="footer-newsletter__text">
                Suscribase para recibir nuestros Boletines de Tecnología.
            </div>

            <form action="" className="footer-newsletter__form">
                <label className="sr-only" htmlFor="footer-newsletter-address">Correo Electronico...</label>
                <input
                    type="text"
                    className="footer-newsletter__form-input form-control"
                    id="footer-newsletter-address"
                    placeholder="Correo Electronico..."
                />
                <button type="submit" className="footer-newsletter__form-button btn btn-primary">Suscribirse</button>
            </form>

            <div className="footer-newsletter__text footer-newsletter__text--social">
                Síguenos en redes sociales.
            </div>

            <ul className="footer-newsletter__social-links">
                {socialLinksList}
            </ul>
        </div>
    );
}
