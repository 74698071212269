// react
import React from 'react';

// third-party
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

// application
import NavPanel from './NavPanel';
import Search from './Search';
import Topbar from './Topbar';


function Header(props) {
    const { layout, pagesContent, pagesContent: { company }, currentUser, history } = props;
    let bannerSection;

    if (layout === 'default') {
        bannerSection = (
            <div className="site-header__middle container">
                <div className="site-header__logo">
                    <Link to="/"><img src={`${company && company.logo}`} alt="ICAutomatizados" height="80px" /></Link>
                </div>
                <div className="site-header__search">
                    <Search context="header" />
                </div>
                <div className="site-header__phone">
                    <div className="site-header__phone-title">
                        <FormattedMessage id="header.phoneLabel" defaultMessage="Customer Service" />
                    </div>
                    <div className="site-header__phone-number">
                        <FormattedMessage id="header.phone" defaultMessage={`${company && company.customer_service}`} />

                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="site-header">
            <Topbar currentUser={currentUser} history={history} />
            {bannerSection}
            <div className="site-header__nav-panel">
                <NavPanel layout={layout} history={history} pagesContent={pagesContent} />
            </div>
        </div>
    );
}

Header.propTypes = {
    /** one of ['default', 'compact'] (default: 'default') */
    layout: PropTypes.oneOf(['default', 'compact']),
};

Header.defaultProps = {
    layout: 'default',
};

export default Header;
