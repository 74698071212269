// react
import React from 'react';
// third-party
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { logout } from '../../store/directusSdk';
import { cartUpdatePrice } from '../../store/cart';
import { currentUserChange } from '../../store/currentUser';
import { CartItemsId } from '../shared/utilities';

function Account(props) {
    const { currentUser, currentUserChange: changecurrentUser, cart, cartUpdatePrice } = props;

    const cartItems = CartItemsId(cart);

    function sendLogout(e) {
        e.preventDefault();
        const result = logout(cartItems);
        result.then((res) => {
            changecurrentUser(null);
            cartUpdatePrice(res.productos, 'logout');
        });
    }

    return (
        <div>
            <div className="account-menu__divider" />
            <Link to="/account/dashboard" className="account-menu__user">
                <div className="account-menu__user-avatar">
                    <img src="images/avatars/avatar-3.jpg" alt="" />
                </div>
                <div className="account-menu__user-info">
                    <div className="account-menu__user-name">{`${currentUser.first_name} ${currentUser.last_name}`}</div>
                    <div className="account-menu__user-email">{currentUser.email}</div>
                </div>
            </Link>
            <div className="account-menu__divider" />
            <ul className="account-menu__links">
                <li><Link to="/account/dashboard">Mi Perfil</Link></li>
                <li><Link to="/account/cotizaciones">Mis Cotizaciones</Link></li>
                <li><Link to="/account/orders">Mis Órdenes</Link></li>
                <li><Link to="/account/addresses">Mis Direcciones</Link></li>
                <li><Link to="/account/password">Cambiar Contraseña</Link></li>
            </ul>
            <div className="account-menu__divider" />
            <ul className="account-menu__links">
                <li><Link to="/" onClick={sendLogout}>Salir</Link></li>
            </ul>
        </div>
    );
}
const mapStateToProps = (state) => ({
    currentUser: state.currentUser,
    cart: state.cart,
});

const mapDispatchToProps = {
    currentUserChange,
    cartUpdatePrice,
};

export default connect(mapStateToProps, mapDispatchToProps)(Account);
