// react
import React from 'react';

// third-party
import { Link } from 'react-router-dom';


export default function BlockCategoriesCompact(props) {
    const { layout, categories } = props;

    const subcategoriesList = categories.map((category, index) => {
        const classes = `block-categories__item category-card category-card--layout--${layout}`;
        return (
            <div key={index} className={classes}>
                <div className=" category-card__body">
                    <div className=" category-card__image">
                        <Link to={category.url}><img src={category.categoryimage ? category.categoryimage.thumbnails[3].url : 'images/not_found.png' } alt="" /></Link>
                    </div>
                    <div className=" category-card__content">
                        <div className=" category-card__name">
                            <Link to={category.url}>{category.descripcion}</Link>
                        </div>
                    </div>
                </div>
            </div>
        );
    });

    return (
        <div className={`block block--highlighted block-categories block-categories--layout--${layout}`}>
            <div className="container">
                <div className="block-categories__list">
                    {subcategoriesList}
                </div>
            </div>
        </div>
    );
}
