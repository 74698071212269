// react
import React from 'react';

// third-party
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Gallery from 'react-grid-gallery';


export default function Project(props) {
    const { layout, project } = props;
    const postClasses = classNames('post__content typography', {
        'typography--expanded': layout === 'full',
    });
    const images = project.projectimages.map((e) => {
        const thumbnail = `https://icawebapi.jacksan.a2hosted.com/public/icaweb/assets/${e.image.private_hash}?key=callery_thumbnail`;
        return {
            src: e.image.data.full_url,
            thumbnail,
            thumbnailWidth: 268,
            thumbnailHeight: 180,
        };
    });

    return (
        <div className={`block post post--layout--${layout}`}>
            <div className={`post__header post-header post-header--layout--${layout}`}>
                <h1 className="post-header__title">{project.title}</h1>
                <br />
            </div>
            <div className="post__featured">
                <img src={project.projectimage} alt="" />
            </div>
            <div className={postClasses}>
                <p align="justify">
                    {project.description}
                </p>
            </div>
            <br />
            <br />
            <div className={postClasses} dangerouslySetInnerHTML={{ __html: project.body }} />
            <h4 className="post__section-title">Galeria</h4>
            <Gallery
                images={images}
                backdropClosesModal
                enableImageSelection={false}
                margin={5}
                imageCountSeparator=" de "
            />

        </div>
    );
}

Project.propTypes = {
    /**
     * post layout
     * one of ['classic', 'full'] (default: 'classic')
     */
    layout: PropTypes.oneOf(['classic', 'full']),
};

Project.defaultProps = {
    layout: 'classic',
};
