// react
import React, { Component } from 'react';

// third-party
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

// application
import moment from 'moment';
import 'moment/locale/es-us';
import Currency from '../shared/Currency';
import Pagination from '../shared/Pagination';

export default class AccountPageOrders extends Component {
    constructor(props) {
        super(props);

        this.state = {
            orders: [],
            page: 1,
            pages: 1,
            start: 0,
            end: 9,
            sinOrders: false,
            loading: true,
        };
    }

    componentDidMount() {
        const { currentUser, getOrders } = this.props;
        const { page } = this.state;
        const allOrders = getOrders(currentUser.id);
        allOrders.then((res) => {
            if (res.data.length > 0) {
                const orders = res.data;
                const pages = Math.ceil(orders.length / 10);
                const end = page * 10;
                const start = end - 10;
                this.setState(() => ({ orders, pages, end, start, loading: false }));
            } else {
                this.setState(() => ({ sinOrders: true, loading: false }));

            }
        });
    }

    handlePageChange = (page) => {
        const end = page * 10;
        const start = end - 10;
        this.setState(() => ({ page, end, start }));
    };

    render() {
        const { company } = this.props;
        const { page, orders, pages, end, start, sinOrders, loading } = this.state;

        const ordersList = orders.map((order) => {
            const date = moment(order.created_on, 'YYYY/MM/DD HH:mm:ss', 'es-us');
            const dateFormated = date.add(date.utcOffset(), 'minutes').format('LLL');
            return (
                <tr key={order.id}>
                    <td>
                        <Link to={`/account/order/${order.id}`}>
                            #
                            {order.id}
                        </Link>
                    </td>
                    <td>{dateFormated}</td>
                    <td>
                        <div className={`badge badge-${order.status === 'pendingforpayment' ? 'info' : 'success'}`}>{order.status === 'pendingforpayment' ? 'Pendiente' : 'Procesada'}</div>
                    </td>
                    <td><Currency value={order.total} /></td>
                </tr>
            );
        });

        return (
            <div className="card">
                <Helmet>
                    <title>{`Historial de Ordenes — ${company.name}`}</title>
                </Helmet>

                <div className="card-header">
                    <h5>Hitorial de Ordenes</h5>
                </div>
                <div className="card-divider" />
                <div className="card-table">
                    <div className="table-responsive-sm">
                        <table>
                            <thead>
                                <tr>
                                    <th>Orden</th>
                                    <th>Fecha</th>
                                    <th>Estado</th>
                                    <th>Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                {loading ? (
                                    <tr><td colSpan="5" ><div className="div-loading" style={{ height: 300 }} /></td></tr>
                                ) : ordersList.slice(start, end)}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="card-divider" />
                <div className="card-footer">
                    {sinOrders && <strong> Actualmente no tiene ordenes en sus historial. </strong>}
                    {pages > 1 && <Pagination current={page} total={pages} onPageChange={this.handlePageChange} siblings={2} />}
                </div>
            </div>
        );
    }
}
