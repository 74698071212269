// react
import React from 'react';

export default function FooterContacts(props) {
    const { company } = props;
    const addresses = company.address.split('$');
    const emails = company.email.split('$');
    const phones = company.phone.split('$');
    return (
        <div className="site-footer__widget footer-contacts">
            <h5 className="footer-contacts__title">Contáctanos</h5>

            {/*   <div className="footer-contacts__text" align="justify">
                {company && company.contactus_comment}
    </div>*/}
            <ul className="footer-contacts__contacts">
                <strong>Panamá</strong>
                <li>
                    <i className="footer-contacts__icon fas fa-home" />
                    {addresses[0]}
                </li>
                <li>
                    <i className="footer-contacts__icon far fa-envelope" />
                    {emails[0]}
                </li>
                <li>
                    <i className="footer-contacts__icon fas fa-phone-alt" />
                    {phones[0]}
                </li>
                <li>
                    <i className="footer-contacts__icon far fa-clock" />
                    {company && company.attention_hours}
                </li>
            </ul>
            <ul className="footer-contacts__contacts">
                <strong>Nicaragua</strong>
                <li>
                    <i className="footer-contacts__icon fas fa-home" />
                    {addresses[1]}
                </li>
                <li>
                    <i className="footer-contacts__icon far fa-envelope" />
                    {emails[1]}
                </li>
                <li>
                    <i className="footer-contacts__icon fas fa-phone-alt" />
                    {phones[1]}
                </li>
                <li>
                    <i className="footer-contacts__icon far fa-clock" />
                    {company && company.attention_hours}
                </li>
            </ul>
        </div>
    );
}
