// react
import React, { useState } from 'react';
import { Link } from 'react-router-dom'
import {
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
} from 'reactstrap';
// third-party
import { connect } from 'react-redux';
import './slides.css';

const BlockSlideShow = ({ items }) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [animating, setAnimating] = useState(false);
    const media = window.matchMedia('(max-width: 540px)').matches;

    const next = () => {
        if (animating) return;
        const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    }

    const previous = () => {
        if (animating) return;
        const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
    }

    const goToIndex = (newIndex) => {
        if (animating) return;
        setActiveIndex(newIndex);
    }

    const slides = items.map((item) => {
        return (
            <CarouselItem
                onExiting={() => setAnimating(true)}
                onExited={() => setAnimating(false)}
                key={item.id}
            >
                <Link to={item.url} >
                    <div className="slidess" style={{ backgroundImage: `url(${item.background})` }}>
                        <div className="container">
                            <img src={media ? item.imagemobile : item.img} alt={item.title} />
                        </div>
                    </div>
                </Link>
            </CarouselItem>
        );
    });

    return (
        <div className="mb-5">
            <Carousel
                activeIndex={activeIndex}
                next={next}
                previous={previous}
            >
                <CarouselIndicators items={items} activeIndex={activeIndex} onClickHandler={goToIndex} />
                {slides}
                <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
                <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
            </Carousel>
        </div>
    );
}

const mapStateToProps = (state) => ({
    locale: state.locale,
    items: state.pagesContent.slides,
});

export default connect(mapStateToProps)(BlockSlideShow);
