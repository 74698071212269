// react
import React, { Component } from 'react';

// third-party
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// application
import Pagination from '../shared/Pagination';
import ProductCard from '../shared/ProductCard';
import {
    Filters16Svg,
    LayoutGrid16x16Svg,
    LayoutGridWithDetails16x16Svg,
    LayoutList16x16Svg,
    Check9x7Svg,
} from '../../svg';
import { sidebarOpen } from '../../store/sidebar';
import { resetFilters } from '../../store/pagesContent';


class ProductsView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            records: 12,
            pages: 1,
            start: null,
            end: null,
            OrderPrice: 'default',
            productsList: [],
            DefaultproductsList: [],
            available: false,
        };
        if (props.filters.length < 1 && props.filterOn.length > 0) {
            props.resetFilters();
        }
    }

    componentDidMount() {
        const { products } = this.props;
        const { records, page } = this.state;
        const productsList = products.map((product) => (
            <div key={product.id} className="products-list__item">
                <ProductCard product={product} />
            </div>
        ));
        const pages = Math.ceil(productsList.length / records);
        const end = page * records;
        const start = end - records;

        this.setState(() => ({ pages, end, start, productsList, DefaultproductsList: productsList }));
    }

    componentDidUpdate(prevProps, prevState) {
        const { products, filterOn, FiltersOnJson } = this.props;
        const { records, page, available } = this.state;
        let productsList;
        if (filterOn.length > 0) {
            let filteredProducts = this.getFiltered(products, filterOn);
            if (available) {
                const availables = filteredProducts.filter((p) => p.available > 0);
                filteredProducts = availables;
            }
            productsList = filteredProducts.map((product) => (
                <div key={product.id} className="products-list__item">
                    <ProductCard product={product} />
                </div>
            ));
        } else {
            let filteredProducts = products;
            if (available) {
                const availables = filteredProducts.filter((p) => p.available > 0);
                filteredProducts = availables;
            }
            productsList = filteredProducts.map((product) => (
                <div key={product.id} className="products-list__item">
                    <ProductCard product={product} />
                </div>
            ));
        }
        const pages = Math.ceil(productsList.length / records);
        const end = page * records;
        const start = end - records;
        const otherCondition = prevState.productsList[(productsList.length - 1)] && (prevState.productsList[(productsList.length - 1)].key !== productsList[(productsList.length - 1)].key);
        const other = prevProps.FiltersOnJson !== FiltersOnJson;
        if (prevState.end !== end || prevState.productsList.length !== productsList.length || otherCondition || other) {
            this.setState(() => ({ pages, end, start, productsList, DefaultproductsList: productsList }));
        }
    }

    setLayout = (layout) => {
        this.setState(() => ({ layout }));
    };

    handlePageChange = (page) => {
        const { records } = this.state;
        const end = page * records;
        const start = end - records;

        this.setState({ page, end, start }, () => window.scrollTo(0, 0));
    };

    handleAvailable = (e) => {
        if (e.target.checked) {
            return this.setState(() => ({ available: true }));
        }
        return this.setState(() => ({ available: false }));
    };

    handleRecordsChange = (event) => {
        const { page: currentPage, productsList } = this.state;
        const records = event.target.value;
        const pages = Math.ceil(productsList.length / records);
        const end = currentPage * records;
        const start = end - records;
        const page = currentPage > pages ? pages : currentPage;
        this.setState(() => ({ records, pages, end, start, page }));
    };

    handleOrderPriceChange = (event) => {
        const { products } = this.props;
        const { DefaultproductsList } = this.state;
        const OrderPrice = event.target.value;
        if (OrderPrice !== 'default') {
            let OrderProducts;
            if (OrderPrice === 'menor') {
                OrderProducts = products.sort((a, b) => a.price - b.price);
            } else {
                OrderProducts = products.sort((a, b) => b.price - a.price);
            }
            const productsList = OrderProducts.map((product) => (
                <div key={product.id} className="products-list__item">
                    <ProductCard product={product} />
                </div>
            ));
            this.setState({ productsList, OrderPrice });
        } else {
            const productsList = DefaultproductsList;
            this.setState({ productsList, OrderPrice });
        }
    };

    getFiltered = (products, filtersOn) => {
        return products.filter((product) => {
            const condiciones = [];
            for (let i = 0; i < filtersOn.length; i += 1) {
                condiciones.push(product[filtersOn[i].name] === filtersOn[i].value);
            }
            const aprobar = condiciones.reduce((val, e) => val && e === true, true);
            if (aprobar) {
                return true;
            }
            return false;
        });
    }

    render() {
        const {
            grid,
            offcanvas,
            layout: propsLayout,
            sidebarOpen,
            filterOn,
            filters,
        } = this.props;
        const { page, layout: stateLayout, end, start, records, productsList, OrderPrice, pages, available } = this.state;
        const layout = stateLayout || propsLayout;
        let viewModes = [
            { key: 'grid', title: 'Cuadricula', icon: <LayoutGrid16x16Svg /> },
            { key: 'grid-with-features', title: 'Cuadricula con caracteristicas', icon: <LayoutGridWithDetails16x16Svg /> },
            { key: 'list', title: 'Lista', icon: <LayoutList16x16Svg /> },
        ];

        viewModes = viewModes.map((viewMode) => {
            const className = classNames('layout-switcher__button', {
                'layout-switcher__button--active': layout === viewMode.key,
            });

            return (
                <button
                    key={viewMode.key}
                    title={viewMode.title}
                    type="button"
                    className={className}
                    onClick={() => this.setLayout(viewMode.key)}
                >
                    {viewMode.icon}
                </button>
            );
        });

        const viewOptionsClasses = classNames('view-options', {
            'view-options--offcanvas--always': offcanvas === 'always',
            'view-options--offcanvas--mobile': offcanvas === 'mobile',
        });

        return (
            <div className="products-view">
                <div className="products-view__options">
                    <div className={viewOptionsClasses}>
                        {filters.length > 0 && (
                            <div className="view-options__filters-button">
                                <button type="button" className="filters-button" onClick={() => sidebarOpen()}>
                                    <Filters16Svg className="filters-button__icon" />
                                    <span className="filters-button__title">Filters</span>
                                    {filterOn.length > 0 && <span className="filters-button__counter">{filterOn.length}</span>}
                                </button>
                            </div>
                        )}
                        <div className="view-options__layout">
                            <div className="layout-switcher">
                                <div className="layout-switcher__list">
                                    {viewModes}
                                </div>
                            </div>
                        </div>
                        <div className="view-options__legend">{`Mostrando ${end > productsList.length ? productsList.length : end} de ${productsList.length} productos`}</div>
                        <div className="view-options__divider" />
                        <div className="view-options__control">
                            <div className="form-check">
                                <span className="form-check-input input-check">
                                    <span className="input-check__body">
                                        <input
                                            id="available"
                                            type="checkbox"
                                            className="input-check__input"
                                            value
                                            checked={available}
                                            onChange={this.handleAvailable}
                                        />
                                        <span className="input-check__box" />
                                        <Check9x7Svg className="input-check__icon" />
                                    </span>
                                </span>
                                <label htmlFor="available">Productos Disponibles</label>
                            </div>
                        </div>
                        <div className="view-options__control">
                            <label htmlFor="view-options-sort">Ordenar por </label>
                            <div>
                                <select className="form-control form-control-sm" id="view-options-sort" value={OrderPrice} onChange={this.handleOrderPriceChange}>
                                    <option value="default">Defecto</option>
                                    <option value="mayor">Mayor Precio</option>
                                    <option value="menor">Menor Precio</option>
                                </select>
                            </div>
                        </div>
                        <div className="view-options__control">
                            <label htmlFor="view-options-limit">Mostar</label>
                            <div>
                                <select className="form-control form-control-sm" value={records} id="view-options-limit" onChange={this.handleRecordsChange}>
                                    <option value="12">12</option>
                                    <option value="24">24</option>
                                    <option value="48">48</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    className="products-view__list products-list"
                    data-layout={layout !== 'list' ? grid : layout}
                    data-with-features={layout === 'grid-with-features' ? 'true' : 'false'}
                >
                    <div className="products-list__body">
                        {productsList.slice(start, end)}
                    </div>
                </div>

                <div className="products-view__pagination">
                    <Pagination
                        current={page}
                        siblings={2}
                        total={pages}
                        onPageChange={this.handlePageChange}
                    />
                </div>
            </div>
        );
    }
}

ProductsView.propTypes = {
    /**
     * array of product objects
     */
    products: PropTypes.array,
    /**
     * products list layout (default: 'grid')
     * one of ['grid', 'grid-with-features', 'list']
     */
    layout: PropTypes.oneOf(['grid', 'grid-with-features', 'list']),
    /**
     * products list layout (default: 'grid')
     * one of ['grid-3-sidebar', 'grid-4-full', 'grid-5-full']
     */
    grid: PropTypes.oneOf(['grid-3-sidebar', 'grid-4-full', 'grid-5-full']),
    /**
     * indicates when sidebar bar should be off canvas
     */
    offcanvas: PropTypes.oneOf(['always', 'mobile']),
};

ProductsView.defaultProps = {
    layout: 'grid',
    grid: 'grid-3-sidebar',
    offcanvas: 'mobile',
};
const mapStateToProps = (state) => ({
    filterOn: state.pagesContent.filters,
    FiltersOnJson: JSON.stringify(state.pagesContent.filters),

});
const mapDispatchToProps = {
    sidebarOpen,
    resetFilters,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ProductsView);
