// react
import React from 'react';

// application
import FooterContacts from './FooterContacts';
import FooterLinks from './FooterLinks';
import FooterNewsletter from './FooterNewsletter';

export default function Footer(props) {
    const { company } = props;
    const informationLinks = [
        { title: 'Nosotros', url: '/site/about-us' },
        { title: 'Contáctanos', url: '/site/contact-us' },
        { title: 'Proyectos', url: '/proyectos' },
        { title: 'Afiliate', url: '/account/login' },
        { title: 'Ayuda', url: '/ayuda' },
        { title: 'Garantia', url: '/garantia' },

    ];

    const accountLinks = [
        { title: 'Localización', url: '/site/contact-us' },
        { title: 'Devoluciones', url: '/devoluciones' },
        { title: 'Polícticas de Privacidad', url: '/site/privacy-policies' },
        { title: 'Términos y Condiciones', url: '/site/terms' },
        { title: 'Condiciones de Venta', url: '/condiciones-de-venta' },
        { title: 'Preguntas Frecuentes', url: '/preguntas-frecuentes' },

    ];

    return (
        <div className="site-footer">
            <div className="container">
                <div className="site-footer__widgets">
                    <div className="row">
                        <div className="col-12 col-md-6 col-lg-4">
                            <FooterContacts company={company} />
                        </div>
                        <div className="col-6 col-md-3 col-lg-2">
                            <FooterLinks title="Información" items={informationLinks} />
                        </div>
                        <div className="col-6 col-md-3 col-lg-2">
                            <FooterLinks title="Mi Cuenta" items={accountLinks} />
                        </div>
                        <div className="col-12 col-md-12 col-lg-4">
                            <FooterNewsletter />
                        </div>
                    </div>
                </div>

                <div className="site-footer__bottom">
                    <div className="site-footer__copyright">
                        {`Ingeniería y Controles Automatizados S.A. Todos los derechos reservados ® 2014 - ${new Date().getFullYear()}.`}
                    </div>
                    <div className="site-footer__payments">
                        <img src="images/payments.png" alt="" />
                    </div>
                </div>
            </div>
        </div>
    );
}
