// react
import React, { useState, useEffect } from 'react';

// third-party
import { connect } from 'react-redux';
import { filtersChange, dropFilters } from '../../store/pagesContent';

function FilterSelect(props) {
    const {
        filter,
        filtersChange,
        dropFilters,
        filterOn,
    } = props;
    const [state, setstate] = useState('');
    useEffect(() => {
        filterOn.forEach((e) => {
            if (e.name === filter.name) {
                return setstate(e.value);
            }
        });
    }, []);
    useEffect(() => {
        if (filterOn.length < 1) {
            return setstate('');
        }
    }, [filterOn.map((e) => e.name)]);

    const handleChange = (e) => {
        const filtro = {};
        filtro.name = filter.name;
        filtro.value = e.target.value;

        if (filtro.value !== '') {
            filtersChange(filtro);
        } else {
            dropFilters(filtro);
        }
        setstate(e.target.value);
    };

    return (
        <div className="form-group">
            <select className="form-control form-control-sm" value={state} onChange={handleChange} >
                <option value="">{filter.name}</option>
                {filter.options.sort((a,b)=>a-b).map((e) => (<option key={e} value={e}>{e}</option>))}
            </select>
        </div>
    );
}
const mapStateToProps = (state) => ({
    filterOn: state.pagesContent.filters,
});
const mapDispatchToProps = {
    filtersChange,
    dropFilters,
};

export default connect(mapStateToProps, mapDispatchToProps)(FilterSelect);
