// react
import React from 'react';

// third-party
import { Helmet } from 'react-helmet-async';

function SitePageServices(props) {
    const { company, services } = props;
    return (
        <div className="block about-us">
            <Helmet>
                <title>{`Nuestros Servicios — ${company.name}`}</title>
            </Helmet>

            <div className="about-us__image" style={{ backgroundImage: 'url("images/services.jpg")' }} />
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 col-xl-10">
                        <div className="about-us__body">
                            <div
                                className="about-us__text typography"
                                dangerouslySetInnerHTML={{ __html: services.body }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SitePageServices;
