// react
import React, { useState } from 'react';

// third-party
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { removeCustomerAddress, getCustomerAddress, setDefaultAddress } from '../../store/directusSdk';
import { AddressesChange } from '../../store/currentUser';
import AccountFormAddress from './AccountFormAddress';

// data stubs


function AccountPageAddresses(props) {
    const [loading, setLoading] = useState(false);
    const { currentUser, AddressesChange } = props;

    const removeAddress = (id) => {
        setLoading(true);
        const remove = removeCustomerAddress(id);
        remove.then(() => {
            getCustomerAddress(currentUser.id).then((res) => {
                AddressesChange(res.data);
                setLoading(false);
            });
        });
    };

    const changeDefaultAddress = (id) => {
        setLoading(true);
        const newAdress = currentUser.addresses.map((e) => ({ id: e.id, defaultaddress: (e.id === id) }));
        const defaultAddress = setDefaultAddress(newAdress);
        defaultAddress.then(() => {
            getCustomerAddress(currentUser.id).then((res) => {
                AddressesChange(res.data);
                setLoading(false);
            });
        });
        defaultAddress.catch((e) => console.log(e));
    };

    let addresses = [];
    if (currentUser.addresses.length > 0) {
        addresses = currentUser.addresses.map((address) => (
            <React.Fragment key={address.id}>
                <div className="addresses-list__item card address-card">
                    {address.defaultaddress ? <div className="address-card__badge">Predeterminada</div> : <button type="button" className="btn btn-secondary btn-xs" onClick={() => changeDefaultAddress(address.id)} disabled={loading}>cambiar a Predeterminada</button>}
                    <div className="address-card__body">
                        <div className="address-card__name">{address.responsable}</div>
                        <div className="address-card__row">
                            {address.address}
                            ,
                            <br />
                            {address.deliverycityid ? `${address.deliverycityid.cityname}. ` : ''}
                            {address.countryid && address.countryid.countryname}
                        </div>
                        <div className="address-card__row">
                            <div className="address-card__row-title">Telefono Principal</div>
                            <div className="address-card__row-content">{address.phone1}</div>
                        </div>
                        <div className="address-card__row">
                            <div className="address-card__row-title">Telefono Opcional</div>
                            <div className="address-card__row-content">{address.phone2}</div>
                        </div>
                        <div className="address-card__row">
                            <div className="address-card__row-title">Correo Electrónico</div>
                            <div className="address-card__row-content">{address.email}</div>
                        </div>
                        <div className="address-card__footer">
                            <Link style={{}} to={`/account/address/${address.id}`}><button type="button" className="btn btn-secondary btn-sm" disabled={loading}>Editar</button></Link>
                            &nbsp;&nbsp;
                            <button onClick={() => removeAddress(address.id)} type="button" className="btn btn-secondary btn-sm" disabled={loading}>Remover</button>
                        </div>
                    </div>
                </div>
                <div className="addresses-list__divider" />
            </React.Fragment>
        ));

        return (
            <div>
                <Helmet>
                    <title>Mis Direcciones - ICAutomatizados S.A</title>
                </Helmet>
                <div className="addresses-list">
                    <Link to="/account/add-address" className="addresses-list__item addresses-list__item--new">
                        <div className="addresses-list__plus" />
                        <div className="btn btn-secondary btn-sm">Agregar</div>
                    </Link>
                    <div className="addresses-list__divider" />
                    {addresses}
                </div>
            </div>
        );
    }

    return <AccountFormAddress type="Agregar" />
}
const mapStateToProps = (state) => ({
    currentUser: state.currentUser,
});

const mapDispatchToProps = {
    AddressesChange,
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountPageAddresses);