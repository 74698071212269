// react
import React from 'react';

// third-party
import classNames from 'classnames';
import {
    Link,
    matchPath,
    Redirect,
    Switch,
    Route,
} from 'react-router-dom';
import { connect } from 'react-redux';

// application
import PageHeader from '../shared/PageHeader';

// pages
import AccountPageAddresses from './AccountPageAddresses';
import AccountAddAddress from './AccountAddAddress';
import AccountPageDashboard from './AccountPageDashboard';
import AccountEditAddress from './AccountEditAddress';
import AccountPageOrderDetails from './AccountPageOrderDetails';
import AccountPageOrders from './AccountPageOrders';
import AccountPageQuotationList from './AccountPageQuotationList';
import AccountPagePassword from './AccountPagePassword';
//import AccountPageProfile from './AccountPageProfile';

import {
    logout,
    getCustomerAddress,
    addCustomerAddress,
    removeCustomerAddress,
    editCustomerAddress,
    searchCustomerAddress,
    setDefaultAddress,
    getDefaultAddress,
    getOrders,
    getOrder,
    getQuotations,
} from '../../store/directusSdk';
import { currentUserChange } from '../../store/currentUser';
import { cartUpdatePrice } from '../../store/cart';
import { CartItemsId } from '../shared/utilities';


function AccountLayout(props) {
    const { match, location, currentUser, history, company, cart, cartUpdatePrice, currentUserChange } = props;
    const cartItems = CartItemsId(cart);

    if (!currentUser) {
        return <Redirect to="/account/login" />;
    }
    const sendLogout = (e) => {
        e.preventDefault();
        const result = Promise.all([currentUserChange(null), logout(cartItems)]);
        result.then((res) => {
            cartUpdatePrice(res.productos, 'logout');
        });
    };

    const breadcrumb = [
        { title: 'Home', url: '/' },
        { title: 'Mi Cuenta', url: '' },
    ];
    /*
    <Route exact path={`${match.path}/profile`} component={(props) => (<AccountPageProfile {...props} currentUser={currentUser} />)} />
    { title: 'Editar Perfil', url: 'profile' },
    { title: 'Detalles de Orden', url: 'orders/5' },
    */
    const links = [
        { title: 'Dashboard', url: 'dashboard' },
        { title: 'Mis Cotizaciones', url: 'cotizaciones' },
        { title: 'Mis Ordenes', url: 'orders' },
        { title: 'Mis Direcciones', url: 'addresses' },
        { title: 'Cambiar Contraseña', url: 'password' },
        { title: 'Salir', url: 'login' },
    ].map((link) => {
        const url = `${match.url}/${link.url}`;
        const isActive = matchPath(location.pathname, { path: url, exact: true });
        const classes = classNames('account-nav__item', {
            'account-nav__item--active': isActive,
        });

        return (
            <li key={link.url} className={classes}>
                {link.url === 'login' ? <Link to="/account/logout" onClick={sendLogout}>{link.title}</Link> : <Link to={url}>{link.title}</Link>}
            </li>
        );
    });
    return (
        <React.Fragment>
            <PageHeader header="Dashboard" breadcrumb={breadcrumb} />

            <div className="block">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-lg-3 d-flex">
                            <div className="account-nav flex-grow-1">
                                <h4 className="account-nav__title">Navegación</h4>
                                <ul>{links}</ul>
                            </div>
                        </div>
                        <div className="col-12 col-lg-9 mt-4 mt-lg-0">
                            <Switch>
                                <Redirect exact from={match.path} to={`${match.path}/dashboard`} />
                                <Route exact path={`${match.path}/dashboard`} component={(props) => (<AccountPageDashboard {...props} currentUser={currentUser} company={company} getOrders={getOrders} />)} />
                                <Route exact path={`${match.path}/cotizaciones`} component={(props) => (<AccountPageQuotationList {...props} currentUser={currentUser} company={company} getQuotations={getQuotations} getDefaultAddress={getDefaultAddress} />)} />
                                <Route exact path={`${match.path}/orders`} component={(props) => (<AccountPageOrders {...props} currentUser={currentUser} company={company} getOrders={getOrders} />)} />
                                <Route exact path={`${match.path}/order/:orderId`} component={AccountPageOrderDetails} company={company} getOrder={getOrder} />
                                <Route exact path={`${match.path}/addresses`} component={(props) => (<AccountPageAddresses {...props}/>)} />
                                <Route exact path={`${match.path}/add-address`} component={(props) => (<AccountAddAddress {...props} addCustomerAddress={addCustomerAddress} currentUser={currentUser} />)} />
                                <Route exact path={`${match.path}/address/:addressId`} component={(props) => (<AccountEditAddress {...props} editCustomerAddress={editCustomerAddress} searchCustomerAddress={searchCustomerAddress} currentUser={currentUser} />)} />
                                <Route exact path={`${match.path}/password`} component={AccountPagePassword} />
                            </Switch>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

const mapStateToProps = (state) => ({
    cart: state.cart,
    currentUser: state.currentUser,
    company: state.pagesContent.company,
});

const mapDispatchToProps = {
    cartUpdatePrice,
    currentUserChange,
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountLayout);