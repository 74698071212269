// react
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';


// third-party
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';

// application
import PageHeader from '../shared/PageHeader';
import Product from '../shared/Product';
import ProductTabs from './ProductTabs';

// blocks
import BlockProductsCarousel from '../blocks/BlockProductsCarousel';

// widgets
import WidgetCategories from '../widgets/WidgetCategories';
import WidgetProducts from '../widgets/WidgetProducts';

// data stubs
import categories from '../../data/shopWidgetCategories';
import { getProduct } from '../../store/directusSdk';


function ShopPageProduct(props) {
    const { layout, sidebarPosition, match: { params: { id } } } = props;

    const [state, setState] = useState({ product: null, loading: true });

    useEffect(() => {
        const producto = getProduct(id);
        producto.then((res) => {
            const product = Object.assign(res.data);
            const bodegas = product.OnHandByWhs.split(',');
            const bod01 = bodegas[0].split(':');
            const bod05 = bodegas[1].split(':');

            const images = [];
            if (product.images !== '') {
                const imag = product.images.split(',');
                imag.forEach((img) => {
                    if (img !== '') {
                        images.push(`images/products/${img}`);
                    }
                });
            } else {
                images.push('images/not_found.png');
            }

            const pdfs = [];
            if (product.pdfs !== '') {
                const pdff = product.pdfs.split(',');
                pdff.forEach((pdf) => {
                    if (pdf !== '') {
                        pdfs.push(`images/pdfs/${pdf}`);
                    }
                });
            }
            const category = { category: product.subcategoryid.categoriaid.descripcion, subcategory: product.subcategoryid.descripcion, cat_id: product.subcategoryid.categoriaid.id, subcat_id: product.subcategoryid.id };
            delete product.subcategoryid;
            delete product.OnHandByWhs;
            product.days = product.days ? parseInt(product.days) : 30;
            product.bod01 = parseInt(bod01[1]);
            product.bod05 = parseInt(bod05[1]);
            product.compareAtPrice = null;
            product.images = images;
            product.pdfs = pdfs;
            setState({ product, category, loading: false });
        });
    }, [id]);
    if (!state.product) {
        return (
            <div className="container">
                <br />
                <br />
                <br />
                <div className="div-loading" />
                <br />
                <br />
                <br />
            </div>
        );
    }

    const breadcrumb = [
        { title: state.category.category, url: `/shop/categoria/${state.category.category}/${state.category.cat_id}` },
        { title: state.category.subcategory, url: `/shop/categoria/${state.category.category}/subcategoria/${state.category.subcategory}/${state.category.cat_id}/${state.category.subcat_id}` },
        { title: state.product.name, url: '' },
    ];
    let content;

    if (layout === 'sidebar') {
        const sidebar = (
            <div className="shop-layout__sidebar">
                <div className="block block-sidebar">
                    <div className="block-sidebar__item">
                        <WidgetCategories categories={categories} location="shop" />
                    </div>
                    <div className="block-sidebar__item d-none d-lg-block">
                        <WidgetProducts title="Latest Products" products={[]} />
                    </div>
                </div>
            </div>
        );

        content = (
            <div className="container">
                <div className={`shop-layout shop-layout--sidebar--${sidebarPosition}`}>
                    {sidebarPosition === 'start' && sidebar}
                    <div className=" shop-layout__content">
                        <div className=" block">
                            <Product product={state.product} layout={layout} />
                            <ProductTabs product={state.product} withSidebar />
                        </div>

                        <BlockProductsCarousel title="Related Products" layout="grid-4-sm" products={[]} withSidebar />
                    </div>
                    {sidebarPosition === 'end' && sidebar}
                </div>
            </div>
        );
    } else {
        content = (
            <React.Fragment>
                <div className="block">
                    <div className="container">
                        <Product product={state.product} layout={layout} />
                        <ProductTabs product={state.product} />
                    </div>
                </div>
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            <Helmet>
                <title>{`${state.product.name} -- ICAutomatizados S.A`}</title>
            </Helmet>

            <PageHeader breadcrumb={breadcrumb} />

            {content}
        </React.Fragment>
    );
}

ShopPageProduct.propTypes = {
    /** one of ['standard', 'sidebar', 'columnar', 'quickview'] (default: 'standard') */
    layout: PropTypes.oneOf(['standard', 'sidebar', 'columnar', 'quickview']),
    /**
     * sidebar position (default: 'start')
     * one of ['start', 'end']
     * for LTR scripts "start" is "left" and "end" is "right"
     */
    sidebarPosition: PropTypes.oneOf(['start', 'end']),
};

ShopPageProduct.defaultProps = {
    layout: 'standard',
    sidebarPosition: 'start',
};

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(ShopPageProduct);